import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap
} from "../components/elements/BlockWithIcon";
import SliderPageHeader from "../components/elements/SliderPageHeader";
import ButtonLink from "../components/elements/ButtonLink";

const WhyIncircle = () => (
  <Index>
    <Seo title="Why InCircle" />
    <SliderPageHeader id="slide-1-inner">
      <h1 className="font-light">
        Right Patient. <br />
        <b className="larger">Right therapy.</b>
      </h1>
      <p>
        InCircle Reduces Unnecessary Costs and Enables <br />
        More Effective Decision Making.
      </p>
      <footer>
        <ButtonLink href="/request-demo/" text="Schedule a Call" type="primary" />
      </footer>
    </SliderPageHeader>
    <Container extraContainerClasses="space-out">
      <BlockWithIconWrap layout="horizontal">
        <BlockWithIcon>
          <BlockWithIconIcon iconName="reduced-costs" />
          <BlockWithIconText>
            <h2>We Enable More Effective Decision Making and Reduce Costs</h2>
            <p>
              InCircle benefits provide management of costly specialty drugs like IG <br />
              and aligns stakeholders to ensure the right patient receives the right drug.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="desktop" />
          <BlockWithIconText>
            <h2>We Bring Leading Medical Experts <br />to Your Desktop</h2>
            <p>
              An abundance of misinformation coupled with inconsistent prescribing <br />
              guidelines has led to overutilization, potential product shortages and <br />
              escalating prices. Thankfully, InCircle offers a revolutionary solution.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="bottles" />
          <BlockWithIconText>
            <h2>We Address the Specialty Drug <br />Dilemma</h2>
            <p>
              Immune Globulin (IG) has been prescribed for a wide variety of diagnoses, <br />
              but many patients do not actually meet prescribed IG criteria.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="stopwatch" />
          <BlockWithIconText>
            <h2>We Make the Process Efficient <br />and Accurate</h2>
            <p>
              InCircle will facilitate the review process and ultimately reduce the overall <br />
              drug spend.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="patient-data" />
          <BlockWithIconText>
            <h2>Harnessing Patient Data and <br />Prescriber Expertise</h2>
            <p>
              The InCircle longitudinal business model also ensures non-responder’s costly <br />
              therapies will not benefit them.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
      </BlockWithIconWrap>
    </Container>
  </Index>
)

export default WhyIncircle
