import React from "react"
import Container from "../layout/Container";

const SliderPageHeader = ({ id, extraContainerClasses = '', children }) => (
  <div id={id} className={`slider-page-header ${extraContainerClasses}`}>
    <Container>
      <div className="content align-center">
        { children }
      </div>
      <div id="slider-colors">
        <div id="slider-colors-inner">
          <div className="slider-color" id="slider-color-1" />
          <div className="slider-color" id="slider-color-2" />
          <div className="slider-color" id="slider-color-3" />
        </div>
      </div>
    </Container>
  </div>
)

export default SliderPageHeader
